import React from "react";
import WaitlistWidget from "./WaitlistWidget";

const CTASection = () => (
  <div id="cta-section" className="bg-zinc-800 text-zinc-100 py-16">
    <div className="max-w-3xl mx-auto text-center px-4 sm:px-6 lg:px-8">
      <h2 className="text-4xl font-bold mb-6">Begin Your Journey</h2>
      <p className="text-xl">Unlock the secrets of your personal knowledge realm with Pensaccio.</p>
      <WaitlistWidget />
    </div>
  </div>
);

export default CTASection;
