import React, { useState } from "react";
import { BookOpen, Magnet, Sparkles, Brain, Compass } from "lucide-react";

const Step = ({ icon: Icon, title, description, position }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`flex flex-col items-center text-center ${position % 2 === 0 ? "md:mt-0" : "md:mt-24"}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="bg-purple-900 p-4 rounded-full mb-4 transform transition-all duration-300 hover:scale-110 hover:rotate-12 z-10">
        <Icon size={32} className="text-zinc-100" />
      </div>
      <div className="w-full relative">
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        {isHovered && (
          <div className="absolute top-full mt-2 bg-zinc-800 text-zinc-100 p-2 rounded-lg shadow-lg z-20">
            {description}
          </div>
        )}
      </div>
    </div>
  );
};

const HowItWorks = () => {
  const steps = [
    { icon: BookOpen, title: "Discover", description: "Uncover knowledge across the vast realms of the Internet" },
    { icon: Magnet, title: "Capture", description: "Attract wisdom to your personal vault" },
    { icon: Sparkles, title: "Distill", description: "Alchemize lengthy texts into potent knowledge extracts" },
    {
      icon: Brain,
      title: "Absorb",
      description: "Consume the distilled insights, expanding your mind's frontiers",
    },
    { icon: Compass, title: "Rediscover", description: "Navigate your personal lore, unearthing hidden connections" },
  ];

  return (
    <section className="bg-zinc-900 text-zinc-100 px-4 ">
      <div className="max-w-7xl mx-auto py-12">
        <h2 className="text-4xl font-bold text-center mb-12">The Arcane Process</h2>
        <div className="relative">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
            {steps.map((step, index) => (
              <Step key={index} {...step} position={index} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
