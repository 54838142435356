import React from "react";
import { ChevronRightIcon } from "lucide-react";
import background from "./hero-background.webp";
import logo from "../logo.png";

const HeroSection = () => (
  <div className="relative bg-zinc-900 text-zinc-100 min-h-screen flex items-center justify-center">
    <div className="absolute inset-0 bg-cover bg-center z-0" style={{ backgroundImage: `url('${background}')` }} />
    <div className="absolute md:relative top-0 z-10 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 pb-12 md:pt-12 text-center bg-zinc-900/80 md:bg-zinc-900/90 rounded">
      <img src={logo} alt="Pensaccio Logo" className="md:hidden mx-auto mt-0 h-20 w-20" />
      <h1 className="text-5xl font-bold mb-6">Unlock Your Mind's Potential</h1>
      <p className="text-xl mb-8 hidden md:block">
        Store, summarize, and rediscover knowledge from across the web in your personal lore vault.
      </p>
      <a href="#cta-section">
        <button className="bg-purple-900 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg flex items-center mx-auto">
          Start Your Journey
          <ChevronRightIcon className="ml-2 h-5 w-5" />
        </button>
      </a>
    </div>
  </div>
);

export default HeroSection;
